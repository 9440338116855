<script>
import gql from "graphql-tag";

export default {
  name: "StorePrewrittenTextSelect",
  components: {
    VxQuery: () => import("./vx/VxQuery"),
  },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: "",
    },
    withoutIntent: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "Configure prewritten texts in settings",
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    query() {
      return gql`
        query ACTION_BUTTON_COMPLETE_BUY_PREWRITTEN_TEXTS {
          ${
            this.withoutIntent
              ? "prewrittenTexts(withoutIntent: true)"
              : "prewrittenTexts"
          } {
            id
            slug
          }
        }
      `;
    },
  },
};
</script>
<template>
  <VxQuery :query="query" fetch-policy="cache-and-network">
    <template #loading>
      <v-select loading label="Select a prewritten text" />
    </template>
    <template #error>
      <v-select
        :error-messages="[`Could not load prewritten texts`]"
        label="Select a prewritten text"
      />
    </template>
    <template #data="{ data: { prewrittenTexts } }">
      <v-select
        v-model="localValue"
        :items="prewrittenTexts"
        :no-data-text="noDataText"
        item-text="slug"
        item-value="id"
        label="Select a prewritten text"
        clearable
        :menu-props="{ maxHeight: `100%` }"
      />
    </template>
  </VxQuery>
</template>
